// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
  BorderOutlined,
  BoxPlotOutlined,
  DeploymentUnitOutlined,
  GatewayOutlined,
  MenuUnfoldOutlined,
  QuestionOutlined,
  SmileOutlined,
  StopOutlined
} from '@ant-design/icons';

import SearchIcon from '@mui/icons-material/Search';
import ScheduleIcon from '@mui/icons-material/Schedule';

// icons
const icons = {
  SearchIcon,
  MenuUnfoldOutlined,
  BoxPlotOutlined,
  StopOutlined,
  BorderOutlined,
  SmileOutlined,
  GatewayOutlined,
  QuestionOutlined,
  DeploymentUnitOutlined,
  ScheduleIcon
};

// ==============================|| MENU ITEMS - SUPPORT ||============================== //

const other = {
  id: 'other',
   //title: <FormattedMessage id=" " />,
  type: 'group',
  children: [
    {
      id: 'meta-search',
      title: <FormattedMessage id="meta-search" />,
      type: 'item',
      url: '/meta-search',
      icon: icons.SearchIcon
    },
    {
      id: 'history',
      title: <FormattedMessage id="history" />,
      type: 'item',
      url: '/history',
      icon: icons.ScheduleIcon
    },
  ]
};

export default other;
