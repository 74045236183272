import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import CommonLayout from 'layout/CommonLayout';
import Loadable from 'components/Loadable';
import MainPage from 'pages/extra-pages/main-page';

// pages routing
const MaintenanceError = Loadable(lazy(() => import('pages/maintenance/404')));
const MaintenanceError500 = Loadable(lazy(() => import('pages/maintenance/500')));
const MaintenanceUnderConstruction = Loadable(lazy(() => import('pages/maintenance/under-construction')));
const MaintenanceComingSoon = Loadable(lazy(() => import('pages/maintenance/coming-soon')));
const Unauthorized = Loadable(lazy(() => import('pages/maintenance/unautorized')));

// render - meta search page
const MetaSearchPage = Loadable(lazy(() => import('pages/extra-pages/meta-search-page')));

// render - history page
const HistoryPage = Loadable(lazy(() => import('pages/extra-pages/history')));

// render - details page
const DetailsPage = Loadable(lazy(() => import('pages/extra-pages/details/details')));

const UserGuard = Loadable(lazy(() => import('utils/route-guard/UserGuard')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <UserGuard>
          <MainLayout />
        </UserGuard>
      ),
      children: [
        {
          path: '',
          children: [
            {
              index: true,
              element: <MainPage /> // Handles /meta-search
            }
          ]
        },
        {
          path: 'meta-search',
          children: [
            {
              index: true,
              element: <MetaSearchPage /> // Handles /meta-search
            },
            {
              path: ':metasearchQuery', // Handles /meta-search/:metasearchQuery
              element: <MetaSearchPage />
            }
          ]
        },
        {
          path: 'history',
          element: <HistoryPage />
        },
        {
          path: 'details/:id', // Dynamic route for details page
          element: <DetailsPage />
        }
      ]
    },
    {
      path: '/maintenance',
      element: <CommonLayout />,
      children: [
        {
          path: '404',
          element: <MaintenanceError />
        },
        {
          path: '500',
          element: <MaintenanceError500 />
        },
        {
          path: 'under-construction',
          element: <MaintenanceUnderConstruction />
        },
        {
          path: 'coming-soon',
          element: <MaintenanceComingSoon />
        },
        {
          path: 'unauthorized',
          element: <Unauthorized/>
        },
      ]
    },
    // Catch-all route for undefined paths
    {
      path: '*',
      element: <Unauthorized />
    }
  ]
};

export default MainRoutes;
