import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project import
// import LogoMain from '../../assets/images/icons/logo.svg';
// import LogoIcon from '../../assets/images/icons/logo-icon-io.svg';
// import { APP_DEFAULT_PATH } from 'config';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = ({ isIcon, sx, to }) => (
  <ButtonBase disableRipple component={Link} to={!to ? "/" : to} sx={isIcon?{sx}:{padding:'15px 10px 10px 40px'}}>
    {isIcon ? <img height={28} src={'https://hilcona.com/wp-content/uploads/2021/11/cropped-cropped-210628_hilcona_website_favicon_variant-32x32-1-32x32.png'} alt='Logo'  /> : 
    <img src={'https://portal.netgate.io-procurement.com/ngtFiles/netgate05/ngtSysImages/instance/logo-hilcona.png'} alt='Logo' height={70} width={100}/>}
  </ButtonBase>
);

LogoSection.propTypes = {
  isIcon: PropTypes.bool,
  sx: PropTypes.object,
  to: PropTypes.string
};

export default LogoSection;
