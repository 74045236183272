import {
    Box,
    Card,
    CardContent,
    Typography,
    useTheme,
    ToggleButtonGroup,
    ToggleButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'; // Default icon
import DescriptionIcon from '@mui/icons-material/Description'; // Example icon for 'reports'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptIcon from '@mui/icons-material/Receipt';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import GridViewIcon from '@mui/icons-material/GridView';

import hilconaData from '../../mapping/hilcona.json';
import { useNavigate } from 'react-router-dom';

const MainPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();

    // State for controlling the layout view (grid or table)
    const [viewMode, setViewMode] = useState('grid');

    // Icon mapping for document types
    const iconMapping = {
        liefs: <NotificationsActiveIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        abrli: <ReceiptLongIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        einau: <ShoppingBasketIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        einre: <DescriptionIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        sscce: <BookmarksIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        frabr: <LocalShippingIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        samre: <DescriptionIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        waree: <ReceiptIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        invoice: <DescriptionIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        co: <DescriptionIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
        standard: <InsertDriveFileIcon sx={{ fontSize: viewMode === 'grid' ? '48px' : '24px', color: theme.palette.primary.dark }} />,
    };

    const documentTypes = Object.keys(hilconaData)
        .filter(key => key !== 'base' && key !== 'general')
        .map(type => ({
            label: type,
            value: `${type.toLowerCase()}`
        }));

    const createCustomEvent = (documentTypeValue) => {
        const searchEvent = new CustomEvent('documentPick', {
            detail: {
                documentType: documentTypeValue,
            },
        });
        window.dispatchEvent(searchEvent);
        navigate("/meta-search");
    };

    const handleCardClick = (documentType) => {
        createCustomEvent(documentType.value);
    };

    const handleViewModeChange = (event, newViewMode) => {
        if (newViewMode !== null) {
            setViewMode(newViewMode);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, p: 0, flexGrow: 1, marginLeft: '-20px', marginRight: '-20px' }}>

            <Box sx={{ display: 'flex', justifyContent: "space-between"}}>
                <Typography variant='h3' color={theme.palette.text.primary} sx={{ fontSize: '18px' }}>
                    <FormattedMessage id="meta-search" />
                </Typography>

                {/* Toggle button to switch between grid and table view */}
                <ToggleButtonGroup
                    value={viewMode}
                    exclusive
                    onChange={handleViewModeChange}
                    sx={{ alignSelf: 'end', mb: 2 }}
                    aria-label="view mode"
                >
                    <ToggleButton value="table" aria-label="table view">
                        <FormatListBulletedIcon sx={{ fontSize: '16px' }} />
                    </ToggleButton>
                    <ToggleButton value="grid" aria-label="grid view">
                        <GridViewIcon sx={{ fontSize: '16px' }} />
                    </ToggleButton>

                </ToggleButtonGroup>
            </Box>

            {viewMode === 'grid' ? (
                <Box
                    sx={{
                        width: "100%",
                        display: "grid",
                        gridTemplateColumns: "repeat(auto-fill, minmax(220px, 1fr))",
                        gap: 3,
                        alignItems: "center",
                        justifyContent: "center",
                        transition: 'width 0.5s ease, opacity 0.3s ease, transform 0.5s ease',
                        transform: viewMode === 'grid' ? 'translateY(0)' : 'translateY(-100%)'
                    }}
                >
                    {documentTypes.map((documentType) => (
                        <Card
                            key={documentType.value}
                            onClick={() => handleCardClick(documentType)}
                            sx={{
                                height: "13rem",
                                boxShadow: "none",
                                fontWeight: "bold",
                                background: theme.palette.background.default,
                                display: "flex",
                                cursor: "pointer",
                                borderRadius: 2,
                                transition: "box-shadow 0.01s",
                                '&:hover': {
                                    border: `1px solid ${theme.palette.primary.dark}`,
                                    boxShadow: `0px 1px 12px ${theme.palette.primary.dark}`,
                                    background: theme.palette.primary.brighter
                                },
                            }}
                        >
                            <CardContent
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    flexGrow: 1,
                                    gap: 1
                                }}
                            >
                                <Box>
                                    {/* Render the icon dynamically based on the document type */}
                                    {iconMapping[documentType.value] || <ReceiptLongIcon className="icon" sx={{ fontSize: "48px", color: theme.palette.primary.dark }} />}
                                </Box>
                                <Box>
                                    <Typography sx={{ userSelect: "none" }} variant="h5">
                                        <FormattedMessage id={documentType.label} />
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            ) : (
                // Table View
                <TableContainer sx={{ boxShadow: 0, border: "none", background: "transparent" }} component={Paper}>
                    <Table >
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <FormattedMessage id="documentType" />
                                </TableCell>
                                <TableCell align='right'>
                                    <FormattedMessage id="last_modified" />
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {documentTypes.map((documentType) => (
                                <TableRow
                                    key={documentType.value}
                                    onClick={() => handleCardClick(documentType)}
                                    sx={{ cursor: 'pointer', '&:hover': { backgroundColor: theme.palette.action.hover } }}
                                >
                                    <TableCell sx={{ display: "flex", gap: 2 }}>
                                        {iconMapping[documentType.value] || <ReceiptLongIcon sx={{ fontSize: "24px", color: theme.palette.primary.dark }} />}
                                        <Typography variant="h6">
                                            <FormattedMessage id={documentType.label} />
                                        </Typography>
                                    </TableCell>
                                    <TableCell align='right'>
                                        Test
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Box>
    );
};

export default MainPage;
