import React, { useState, useEffect } from 'react';
import { Box, Typography, OutlinedInput, TextField, Autocomplete } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import hilconaData from '../mapping/hilcona.json';
import { FormattedMessage, useIntl } from 'react-intl';

const BasicDetails = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  invoiceNumber,
  setInvoiceNumber,
  countryOfOrigin,
  setCountryOfOrigin,
  documentNumber,
  setDocumentNumber,
  documentType,
  setDocumentType,
  documentCategory,
  setDocumentCategory,
  setDocumentFields,
  profile,
  setProfile,
}) => {
  const documentCategories = Object.keys(hilconaData)
    .filter(key => key !== 'base' && key !== 'general')
    .map(type => ({
      label: type,
      value: `${type.toLowerCase()}`
    }));

  const handleDocCatChange = (event, newValue) => {
    setDocumentCategory(newValue?.value || '');
    setDocumentFields({});
  };

  const intl = useIntl();

  return (
    <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Typography>
        <FormattedMessage id="basicDetails" />
      </Typography>

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <DatePicker
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ width: '50%' }} />}
              inputFormat={intl.formatMessage({ id: "dateFormatType" })}
            />
            <DatePicker
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ width: '50%' }} />}
              inputFormat={intl.formatMessage({ id: "dateFormatType" })}
            />
          </Box>
        </LocalizationProvider>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <OutlinedInput
            size="large"         
            placeholder={intl.formatMessage({ id: "invoiceNum" })}
            value={invoiceNumber}
            onChange={(event) => setInvoiceNumber(event.target.value)}
            sx={{ width: '50%' }}
          />
          <OutlinedInput
            size="large"
            placeholder={intl.formatMessage({ id: "country" })}
            value={countryOfOrigin}
            onChange={(event) => setCountryOfOrigin(event.target.value)}
            sx={{ width: '50%' }}
          />
        </Box>
        <OutlinedInput
          size="large"
          placeholder={intl.formatMessage({ id: "document_number" })}
          value={documentNumber}
          onChange={(event) => setDocumentNumber(event.target.value)}
          sx={{ width: '100%' }}
        />

        <OutlinedInput
          size="large"
          placeholder={intl.formatMessage({ id: "document_type" })}
          value={documentType}
          onChange={(event) => setDocumentType(event.target.value)}
          sx={{ width: '100%' }}
        />

        <OutlinedInput
          size="large"
          placeholder={intl.formatMessage({ id: "profile" })}
          value={profile}
          onChange={(event) => setProfile(event.target.value)}
          sx={{ width: '100%' }}
        />
        <Box sx={{ width: '100%' }}>
          <Autocomplete
            value={documentCategories.find(type => type.value === documentCategory) || null}
            onChange={handleDocCatChange}
            options={documentCategories}
            getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder={intl.formatMessage({ id: "category" })} fullWidth />}
          />
        </Box>
      </Box>
    </Box>
  );
};

const DocumentFields = ({ documentCategory, setDocumentFields, fieldAmount, documentFields }) => {
  const [fields, setFields] = useState([]);
  const intl = useIntl();

  useEffect(() => {
    if (documentCategory) {
      const docTypeKey = documentCategory.toUpperCase();
      const documentSpecificFields = hilconaData[docTypeKey] || [];
      const generalFields = (hilconaData.general || []).slice().reverse();
      const combinedFields = [...new Set([...generalFields, ...documentSpecificFields])];
      setFields(combinedFields);
    } else {
      setFields([]);
    }
  }, [documentCategory]);

  const handleFieldChange = (field, value) => {
    setDocumentFields(prevFields => ({
      ...prevFields,
      [field]: value
    }));
  };

  if (!documentCategory || fields.length === 0) {
    return null;
  }

  return (
    <Box sx={{ mt: 0, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
      <Box
        sx={{
          maxHeight: 55 * fieldAmount,
          overflowY: fields.length > fieldAmount ? 'auto' : 'unset',
          pr: 1,
        }}
      >
        {fields.map((field) => (
          <OutlinedInput
            key={field}
            size="large"
            placeholder={intl.formatMessage({ id: field })}
            value={documentFields[field] || ''} 
            onChange={(e) => handleFieldChange(field, e.target.value)}
            sx={{ width: '100%', mb: 2 }}
          />
        ))}
      </Box>
    </Box>
  );
};

export { BasicDetails, DocumentFields };
