// material-ui
import { Box, Button, Chip, FormControl, IconButton, InputAdornment, OutlinedInput, Stack, useTheme } from '@mui/material';

import TuneIcon from '@mui/icons-material/Tune';
import SearchIcon from '@mui/icons-material/Search';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import { BasicDetails, DocumentFields } from 'components/expandedSearch';
import CloseIcon from '@mui/icons-material/Close'
import { useIntl } from 'react-intl';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';


// ==============================|| HEADER CONTENT - SEARCH ||============================== //

const Search = () => {
  const theme = useTheme()
  // Use useLocalStorage for state management
  const [searchValue, setSearchValue] = useLocalStorage('searchValue', '');
  const [startDate, setStartDate] = useLocalStorage('startDate', null);
  const [endDate, setEndDate] = useLocalStorage('endDate', null);
  const [documentType, setDocumentType] = useLocalStorage('documentType', '');
  const [invoiceNumber, setInvoiceNumber] = useLocalStorage('invoiceNumber', '');
  const [countryOfOrigin, setCountryOfOrigin] = useLocalStorage('countryOfOrigin', '');
  const [documentNumber, setDocumentNumber] = useLocalStorage('documentNumber', '');
  const [documentFields, setDocumentFields] = useLocalStorage('documentFields', {});
  const [showAccordion, setShowAccordion] = useLocalStorage('showAccordion', false);
  const [profile, setProfile] = useLocalStorage('profile', '');
  const navigate = useNavigate();

  const handleDocumentPick = (event) => {
    setDocumentType(event.detail.documentType);
  };

  window.addEventListener('documentPick', handleDocumentPick);

  const createCustomEvent = () => {
    setShowAccordion(false); 
    // Dispatch custom event
    const searchEvent = new CustomEvent('customSearch', {
      detail: {
        searchValue,
        startDate,
        endDate,
        documentType,
        invoiceNumber,
        countryOfOrigin,
        documentNumber,
        documentFields,
        profile
      },
    });
    window.dispatchEvent(searchEvent); // Dispatch the custom event
    navigate("/meta-search");
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      createCustomEvent()
    }
  };

  const intl = useIntl()

  const getActiveFilters = () => {
    const activeFilters = []
    if (startDate) activeFilters.push({ label: 'startDate', key: 'startDate' })
    if (endDate) activeFilters.push({ label: 'endDate', key: 'endDate' })
    if (invoiceNumber) activeFilters.push({ label: 'invoiceNum', key: 'invoiceNumber' })
    if (countryOfOrigin) activeFilters.push({ label: 'country', key: 'countryOfOrigin' })
    if (documentNumber) activeFilters.push({ label: 'delivery_note_number', key: 'documentNumber' })
    if (documentType) activeFilters.push({ label: 'documentType', key: 'documentType' })
    if (profile) activeFilters.push({ label: 'profile', key: 'profile' })

    Object.entries(documentFields).forEach(([key, value]) => {
      if (value) activeFilters.push({ label: key, key })
    })

    return activeFilters
  }

  const clearAllFilters = () => {
    setSearchValue('');
    setStartDate(null);
    setEndDate(null);
    setDocumentType('');
    setInvoiceNumber('');
    setCountryOfOrigin('');
    setDocumentNumber('');
    setDocumentFields({});
    setProfileFields('');
  };

  const handleDeleteFilter = (filterKey) => {
    switch (filterKey) {
      case 'startDate':
        setStartDate(null)
        break
      case 'endDate':
        setEndDate(null)
        break
      case 'documentType':
        setDocumentType('')
        setDocumentFields({})
        break
      case 'invoiceNumber':
        setInvoiceNumber('')
        break
      case 'countryOfOrigin':
        setCountryOfOrigin('')
        break
      case 'documentNumber':
        setDocumentNumber('')
        break
      case 'profile':
        setProfile('')
        break
      default:
        if (Object.keys(documentFields).includes(filterKey)) {
          setDocumentFields(prevFields => {
            const updatedFields = { ...prevFields }
            delete updatedFields[filterKey]
            return updatedFields
          })
        }
        break
    }
  }

  useEffect(() => {
    setShowAccordion(false);
  }, []);

  const activeFilters = getActiveFilters();
  const activeFilterCount = activeFilters.length;

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
      <FormControl sx={{ width: { xs: '100%', md: 624 } }}>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 1 }}>
          <OutlinedInput
            size="medium"
            id="header-search"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            onKeyDown={handleKeyDown}
            startAdornment={
              <InputAdornment position="start" sx={{ mr: -0.5 }}>
                <SearchIcon />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <Box
                  sx={{
                    width: '22px',
                    height: '22px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: theme.palette.primary.main,
                    fontSize: '0.8rem',
                    userSelect: "none"
                  }}
                >
                  {activeFilterCount}
                </Box>
              </InputAdornment>
            }
            aria-describedby="header-search-text"
            inputProps={{ 'aria-label': 'metadata search' }}
            placeholder="Search Meta data"
            sx={{ width: '100%', backgroundColor: 'background.paper' }}
          />
          <IconButton onClick={() => setShowAccordion((prev) => !prev)}>
            <TuneIcon sx={{ color: 'primary.main' }} />
          </IconButton>

        </Box>
      </FormControl>

      <Box
        boxShadow={2}
        borderRadius={1}
        sx={{
          flex: showAccordion ? 3 : 0,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          width: 580,
          overflow: 'hidden',
          transform: showAccordion ? 'translateY(0)' : 'translateY(-100%)',
          opacity: showAccordion ? 1 : 0,
          transition: 'width 0.5s ease, opacity 0.3s ease, transform 0.5s ease',
          position: 'absolute',
          backgroundColor: theme.palette.background.paper,
          p: 2,
          mt: 1,
          zIndex: -1
        }}
      >

        <Box className='sec_align' sx={{
          pb: .7,
          overflowY: 'auto', width: '100%',
          '&::-webkit-scrollbar': {
            height: '2px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.dark,
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            background: theme.palette.background.main,
          },
        }}>
          {getActiveFilters().length > 0 && (
            <Stack direction="row" spacing={1} alignItems="center">
              {getActiveFilters().map((filter, index) => (
                <Chip
                  key={index}
                  label={intl.formatMessage({ id: filter.label })}
                  onDelete={() => handleDeleteFilter(filter.key)}
                  deleteIcon={<CloseIcon />}
                  sx={{
                    backgroundColor: theme.palette.primary.lighter,
                    color: theme.palette.primary.main,
                    border: 1,
                    borderColor: theme.palette.primary.dark,
                    '& .MuiChip-deleteIcon': {
                      color: theme.palette.primary.dark,
                      '&:hover': {
                        color: theme.palette.primary.main
                      },
                    },
                  }}
                />
              ))}
            </Stack>
          )}
        </Box>


        <BasicDetails
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          invoiceNumber={invoiceNumber}
          setInvoiceNumber={setInvoiceNumber}
          countryOfOrigin={countryOfOrigin}
          setCountryOfOrigin={setCountryOfOrigin}
          documentNumber={documentNumber}
          setDocumentNumber={setDocumentNumber}
          documentType={documentType}
          setDocumentType={setDocumentType}
          setDocumentFields={setDocumentFields}
          profile={profile}
          setProfile={setProfile}
        />
        <DocumentFields
          documentType={documentType}
          setDocumentFields={setDocumentFields}
          documentFields={documentFields}
          fieldAmount={4}
        />
        <Box className='abc1'
          sx={{
            position: 'sticky',
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: 0,
            zIndex: 1,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              clearAllFilters(); // Clear all filters
              setShowAccordion(false); // Close the accordion
            }}
            sx={{ flex: 1, mr: 1 }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={createCustomEvent}
            sx={{ flex: 1, ml: 1 }}
          >
            Search
          </Button>
        </Box>
      </Box>

    </Box>
  );
};

export default Search;
